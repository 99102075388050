{
  "name": "esg-webapp",
  "version": "4.0.0",
  "private": true,
  "sideEffects": false,
  "type": "module",
  "scripts": {
    "clean": "rimraf build && rimraf .cache && rimraf public/build && rimraf node_modules",
    "build": "remix build",
    "dev": "remix dev --manual",
    "start": "dotenv -e .env remix-serve ./build/index.js",
    "typecheck": "tsc",
    "docker:up": "docker-compose up -d",
    "deploy:esg": "pm2 deploy ecosystem.config.cjs esg",
    "deploy:esgv2": "pm2 deploy ecosystem.config.cjs esgv2",
    "deploy:esgtest": "pm2 deploy ecosystem.config.cjs esgtest",
    "esg:seed": "node cli/_seeder/index.js"
  },
  "dependencies": {
    "@ant-design/react-slick": "^1.0.2",
    "@azure/msal-browser": "^3.27.0",
    "@azure/msal-node": "^2.15.0",
    "@azure/msal-react": "^2.1.0",
    "@emotion/react": "^11.11.4",
    "@emotion/styled": "^11.11.5",
    "@glideapps/glide-data-grid": "^5.3.0",
    "@hookform/resolvers": "^3.3.1",
    "@mrblenny/react-flow-chart": "^0.0.14",
    "@radix-ui/react-accordion": "^1.1.2",
    "@radix-ui/react-alert-dialog": "^1.0.4",
    "@radix-ui/react-aspect-ratio": "^1.0.3",
    "@radix-ui/react-avatar": "^1.0.3",
    "@radix-ui/react-checkbox": "^1.0.4",
    "@radix-ui/react-collapsible": "^1.0.3",
    "@radix-ui/react-context-menu": "^2.1.4",
    "@radix-ui/react-dialog": "^1.0.5",
    "@radix-ui/react-dropdown-menu": "^2.0.5",
    "@radix-ui/react-hover-card": "^1.0.6",
    "@radix-ui/react-icons": "^1.3.0",
    "@radix-ui/react-label": "^2.0.2",
    "@radix-ui/react-menubar": "^1.0.3",
    "@radix-ui/react-navigation-menu": "^1.1.3",
    "@radix-ui/react-popover": "^1.0.6",
    "@radix-ui/react-progress": "^1.0.3",
    "@radix-ui/react-radio-group": "^1.1.3",
    "@radix-ui/react-scroll-area": "^1.0.4",
    "@radix-ui/react-select": "^1.2.2",
    "@radix-ui/react-separator": "^1.0.3",
    "@radix-ui/react-slider": "^1.1.2",
    "@radix-ui/react-slot": "^1.0.2",
    "@radix-ui/react-switch": "^1.0.3",
    "@radix-ui/react-tabs": "^1.0.4",
    "@radix-ui/react-toast": "^1.1.4",
    "@radix-ui/react-toggle-group": "^1.1.0",
    "@radix-ui/react-tooltip": "^1.0.6",
    "@react-pdf-viewer/bookmark": "^3.12.0",
    "@react-pdf-viewer/core": "^3.12.0",
    "@react-pdf-viewer/default-layout": "^3.12.0",
    "@react-pdf-viewer/page-navigation": "^3.12.0",
    "@react-pdf/renderer": "^3.1.14",
    "@remix-run/css-bundle": "^2.8.1",
    "@remix-run/node": "^2.10.1",
    "@remix-run/react": "^2.10.1",
    "@remix-run/serve": "^2.8.1",
    "@tanstack/react-table": "^8.10.1",
    "@tinymce/tinymce-react": "^5.0.1",
    "@types/ndjson": "^2.0.4",
    "@types/object-path": "^0.11.4",
    "async": "^3.2.5",
    "bcrypt": "^5.1.1",
    "chart.js": "^4.4.0",
    "class-variance-authority": "^0.7.0",
    "clsx": "^2.0.0",
    "cmdk": "^0.2.0",
    "crypto-js": "^4.1.1",
    "date-fns": "^2.30.0",
    "date-fns-tz": "^2.0.0",
    "dayjs": "^1.11.10",
    "dotenv": "^16.3.1",
    "dotenv-cli": "^7.3.0",
    "echarts": "^5.4.3",
    "echarts-for-react": "^3.0.2",
    "embla-carousel-react": "^8.0.0-rc17",
    "equation-resolver": "^1.0.0",
    "exceljs": "^4.4.0",
    "file-saver": "^2.0.5",
    "framer-motion": "^11.11.9",
    "fs-extra": "^11.2.0",
    "isbot": "^4.1.0",
    "jsonwebtoken": "^9.0.2",
    "jspdf": "^2.5.1",
    "jspdf-autotable": "^3.8.2",
    "ka-table": "^8.8.1",
    "keen-slider": "^6.8.6",
    "lodash": "^4.17.21",
    "lodash-es": "^4.17.21",
    "lucide-react": "^0.303.0",
    "marked": "^9.1.0",
    "mongodb": "^6.10.0",
    "mongoose": "^7.5.2",
    "mongoose-autopopulate": "^1.0.1",
    "mongoose-delete": "^1.0.1",
    "mongoose-paginate-v2": "^1.7.4",
    "nanoid": "^5.0.1",
    "ndjson": "^2.0.0",
    "newrelic": "^11.12.0",
    "next-themes": "^0.2.1",
    "node-cache": "^5.1.2",
    "node-cron": "^3.0.2",
    "node-schedule": "^2.1.1",
    "nodemailer": "^6.9.5",
    "nuka-carousel": "^6.0.3",
    "object-path": "^0.11.8",
    "pdfjs-dist": "3.4.120",
    "powerbi-client": "^2.23.1",
    "powerbi-client-react": "^1.4.0",
    "raw-body": "^3.0.0",
    "raw-loader": "4",
    "react": "18.3.0-canary-21a161fa3-20230609",
    "react-alice-carousel": "^2.8.0",
    "react-chartjs-2": "^5.2.0",
    "react-data-export": "^0.6.0",
    "react-day-picker": "^8.8.2",
    "react-dom": "18.3.0-canary-21a161fa3-20230609",
    "react-equation": "^1.0.0",
    "react-hook-form": "^7.46.1",
    "react-hot-toast": "^2.4.1",
    "react-icons": "^4.11.0",
    "react-lottie-lightweight": "^0.0.1",
    "react-multi-email": "^1.0.16",
    "react-pdf": "^7.6.0",
    "react-player": "^2.13.0",
    "react-querybuilder": "^6.5.5",
    "react-responsive-carousel": "^3.2.23",
    "react-select": "^5.8.0",
    "react-slick": "^0.29.0",
    "react-sortablejs": "^6.1.4",
    "react-svg": "^16.1.34",
    "react-tagsinput": "^3.20.3",
    "react-tailwindcss-select": "^1.8.5",
    "recharts": "^2.8.0",
    "reflect-metadata": "^0.2.2",
    "remix-utils": "^7.6.0",
    "slick-carousel": "^1.8.1",
    "slugify": "^1.6.6",
    "sonner": "^1.3.1",
    "sortablejs": "^1.15.2",
    "styled-components": "^6.1.8",
    "tailwind-merge": "^2.2.0",
    "tailwindcss-animate": "^1.0.7",
    "tinymce": "^7.1.0",
    "typedi": "^0.10.0",
    "use-subscription": "^1.8.2",
    "usehooks-ts": "^3.1.0",
    "vaul": "^0.8.0",
    "winston": "^3.13.0",
    "winston-daily-rotate-file": "^5.0.0",
    "xlsx": "^0.18.5",
    "xlsx-style": "^0.8.13",
    "yup": "^1.2.0",
    "zustand": "^4.5.2"
  },
  "devDependencies": {
    "@ckeditor/ckeditor5-core": "^41.3.1",
    "@ckeditor/ckeditor5-dev-translations": "^39.8.0",
    "@remix-run/dev": "^2.8.1",
    "@remix-run/eslint-config": "^2.8.1",
    "@tailwindcss/forms": "^0.5.7",
    "@types/async": "^3.2.24",
    "@types/bcrypt": "^5.0.0",
    "@types/crypto-js": "^4.1.2",
    "@types/jsonwebtoken": "^9.0.6",
    "@types/lodash": "^4.14.198",
    "@types/lodash-es": "^4.17.9",
    "@types/mongoose-delete": "^1.0.0",
    "@types/node-cron": "^3.0.9",
    "@types/nodemailer": "^6.4.10",
    "@types/pdfjs-dist": "^2.10.378",
    "@types/react": "^18.2.22",
    "@types/react-dom": "^18.2.7",
    "@types/react-tagsinput": "^3.20.6",
    "@types/sortablejs": "^1.15.8",
    "@types/use-subscription": "^1.0.2",
    "autoprefixer": "^10.4.16",
    "eslint": "^8.49.0",
    "mailhog": "^4.16.0",
    "pm2": "^5.3.0",
    "postcss": "^8.4.33",
    "rimraf": "^5.0.5",
    "tailwindcss": "^3.4.1",
    "ts-node": "^10.9.2",
    "typescript": "^5.1.6"
  },
  "engines": {
    "node": ">=18.0.0"
  }
}
